<template>
  <n-auth-panel>
    <template v-slot:heading>
      <n-html :text="$t('auth.forgot_password.title')" />
    </template>

    <template v-slot:content v-if="form_states.request">
      <n-html :text="$t('auth.forgot_password.success')" />
    </template>

    <template v-slot:content v-else>
      <n-html :text="$t('auth.forgot_password.subtitle')" />
    </template>

    <div v-if="!form_states.request">
      <n-form-group
        id="input-group-email"
        :label="$t('form.email.label')"
        label-for="input-email"
        :invalid-feedback="$t('form.email.feedback.invalid')"
        :state="form_states.email"
      >
        <n-form-input-default
          id="input-email"
          :placeholder="$t('form.email.placeholder')"
          v-model="email"
          type="email"
          v-on:keyup.enter.native="register"
        />
      </n-form-group>

      <n-form-group
        class="mt-11"
        id="button-group-forgot"
        :invalid-feedback="$t('auth.forgot_password.error')"
        :state="form_states.request"
      >
        <n-button-regular
          full-width
          @click.native="register"
          :disabled="!valid"
        >
          <n-html :text="$t('auth.forgot_password.button')" />
        </n-button-regular>
      </n-form-group>
    </div>
  </n-auth-panel>
</template>

<script>
import { mapGetters } from "vuex";
import validation from "../../mixins/validation";

export default {
  name: "pages.auth.forgot_password",
  mixin: [validation],
  data: () => ({
    email: "",
    form_states: {
      email: null,
      request: null
    }
  }),
  computed: {
    ...mapGetters({
      locale: "application/getLocale"
    }),
    valid() {
      return !!this.email & this.validateEmail(this.email);
    }
  },
  methods: {
    register() {
      if (this.valid) {
        this.form_states.request = null;
        this.$store
          .dispatch("user/request_password_reset", {
            email: this.email,
            locale: this.locale.substring(0, 2)
          })
          .then(() => (this.form_states.request = true))
          .catch(() => (this.form_states.request = false));
      }
    }
  },
  watch: {
    email(n) {
      this.form_states.email = n && this.validateEmail(n);
    }
  }
};
</script>
